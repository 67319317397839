import { orchestrator } from '../../orchestrator';
import { getSessionStore, sessionStoreKeys } from '../sessionStore';

const houseHoldIdRegexEn = /(?:\/households\/)([^/]*)(?:\/)/i;
const houseHoldIdRegexDe = /(?:\/haushalt\/)([^/]*)(?:\/)/i;

export const isSyncId = (id: string) => /^\d+$/.test(id);

export const getHouseholdId = (route: string) => {
  if (
    orchestrator().elements().getCurrentMicrofrontendIframe()?.luigi?.currentNode.isExternal &&
    getSessionStore<string>(sessionStoreKeys.householdId).getItem()
  )
    return getSessionStore<string>(sessionStoreKeys.householdId).getItem() as string;

  if (!route) return undefined;

  const id = route.match(houseHoldIdRegexEn)?.[1] || route.match(houseHoldIdRegexDe)?.[1];

  if (!id || !isSyncId(id)) return undefined;

  return id;
};
