import { DxButton, DxContainer } from '@dvag/design-system-react';
import { appNames } from '@dvag/dfs-constant-config';
import { useFavoritesAutosave } from '@dvag/dfs-ui-blocks/components';
import { useUpdateFavorites } from '@dvag/dfs-ui-blocks/hooks';
import { useMemo, useState } from 'react';

import { sendCustomMessage } from 'config/communication';
import { i18next } from 'config/i18n';
import { navigate } from 'utils/navigation';
import { checkIcon } from 'utils/utils';

import './style.css';
import { getToken } from '../../graphql/getGraphQLClient';
import { useGetFavoritesWithAction } from '../../hooks/useGetFavoritesWithAction';
import { errorToast } from '../../notification';

import { FavoritesDrawerWithSearch } from './FavoritesWithSearch';


export const FavoritesDrawer = ({ householdId }: { householdId?: string }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const headerMenuItem: HTMLDxHeaderMenuItemElement | null = document.querySelector('#favoriten');
  const favoritesWithAction = useGetFavoritesWithAction(householdId);

  const favorites = useMemo(() => favoritesWithAction.data, [favoritesWithAction.data]);

  const updateFavorites = useUpdateFavorites(errorToast, getToken);

  const { move, remove, getAllResources } = useFavoritesAutosave(
    favorites,
    updateFavorites.mutateAsync,
    () => {},
    (isSubmitSuccessfulParam) => {
      if (!isSubmitSuccessfulParam) sendCustomMessage({ id: 'favorites.updated' });
      setIsSubmitting(isSubmitSuccessfulParam);
    },
    setIsDirty,
  );

  const navigateAddFavoritesPage = () => {
    navigate(`/${appNames.advisor}/haushalt/${householdId}/favoriten`);
    headerMenuItem?.closeSidebar();
  };

  const onEditModeSet = () => {
    setIsEditMode((prev) => !prev);
  };

  return (
    <DxContainer color="transparent" className="favorites-container">
      <FavoritesDrawerWithSearch
        favorites={getAllResources()}
        isEditMode={isEditMode}
        onItemMove={move}
        onItemRemove={remove}
        onItemClick={favoritesWithAction.onItemClick}
      />
      <DxContainer color="transparent" className="action-buttons-container">
        {isEditMode ? (
          <DxButton
            type="primary-m"
            stretch
            label={i18next.t('favorites.finishEditFavorites')}
            icon={checkIcon('check')}
            onClick={onEditModeSet}
            disabled={isSubmitting || isDirty}
            id="finish-edit-favorites-button"
            data-testid="finish-edit-favorites-button"
          />
        ) : (
          <DxContainer className="readonly-action-buttons">
            <DxButton
              type="primary-m"
              stretch
              label={i18next.t('favorites.addFavorites')}
              icon={checkIcon('plus-kreis')}
              onClick={navigateAddFavoritesPage}
              id="add-favorites-button"
              data-testid="add-favorites-button"
            />
            <DxButton
              type="secondary-m"
              stretch
              label={i18next.t('favorites.editFavorites')}
              icon={checkIcon('bearbeiten')}
              onClick={onEditModeSet}
              id="edit-favorites-button"
              data-testid="edit-favorites-button"
            />
          </DxContainer>
        )}
      </DxContainer>
    </DxContainer>
  );
};
