import { appNames } from '@dvag/dfs-constant-config';

export const getPathList = (householdId: string) => [
  {
    id: 'da82c74f-3bba-447c-bfeb-e810f2c91192',
    uri: `/${appNames.vpd}/haushalt/${householdId}/analyse/unternehmenspraesentation`,
  },
  {
    id: 'c8b7b577-c329-46c8-b6d1-df1e7327f358',
    uri: `/${appNames.person}/haushalt/${householdId}/analyse/wuensche-und-ziele`,
  },
  {
    id: '2944c1ab-38ba-4c74-84c1-601fb3fa9719',
    uri: `/${appNames.person}/haushalt/${householdId}/analyse/ffg/sparer-typ/start`,
  },
  {
    id: '9f733058-7599-4d28-acff-3ccd8d9375ad',
    uri: `/${appNames.person}/haushalt/${householdId}/analyse/ffg/sparer-typ/merkmale`,
  },
  {
    id: 'afc1e28c-95cb-4570-b529-74713a62a9d8',
    uri: `/${appNames.person}/haushalt/${householdId}/analyse/ffg/sparer-typ/gegenwaertig`,
  },
  {
    id: 'd6e671a4-6d93-498f-afc9-9ffd4737e6f4',
    uri: `/${appNames.person}/haushalt/${householdId}/analyse/ffg/sparer-typ/gewuenscht`,
  },
  {
    id: '08618e4f-d4f7-4943-80d6-76d8e57a6422',
    uri: `/${appNames.vpd}/haushalt/${householdId}/analyse/ffg/erklaerung`,
  },
  {
    id: '242964a7-0be9-4bbe-80a5-57c32bd5f76b',
    uri: `/${appNames.budget}/haushalt/${householdId}/analyse/vermoegensbilanz`,
  },
  {
    id: 'e1086ffc-d74d-4a12-95ce-609ec9809e0e',
    uri: `/${appNames.budget}/haushalt/${householdId}/analyse/vergleich`,
  },
  {
    id: '6614c7ef-fd26-4565-94f9-3e7845243913',
    uri: `/${appNames.budget}/haushalt/${householdId}/analyse/einkommen`,
  },
  {
    id: '40a21522-1b3e-4623-9039-03610c6b423a',
    uri: `/${appNames.budget}/haushalt/${householdId}/analyse/einkommen/optimum`,
  },
  {
    id: 'dcd519dc-aecc-4e33-9c65-16aece71dda6',
    uri: `/${appNames.budget}/haushalt/${householdId}/analyse/haushaltsbudget/ausgaben`,
  },
  {
    id: 'cd15ede1-b86b-4147-aa36-153074225db8',
    uri: `/${appNames.budget}/haushalt/${householdId}/analyse/haushaltsbudget/vertragsuebersicht`,
  },
  {
    id: '430b4e8a-f553-4849-b3fb-cf01475aadda',
    uri: `/${appNames.budget}/haushalt/${householdId}/analyse/sparbetrag`,
  },
  {
    id: '82ac5f6d-ad25-491d-a4e6-a27fcda29bc9',
    uri: `/${appNames.budget}/haushalt/${householdId}/analyse/zwei-konten-modell`,
  },
  {
    id: '6f334767-21a7-4a62-933d-cfe0507f36ce',
    uri: `/${appNames.budget}/haushalt/${householdId}/analyse/ffg/finanznote/start`,
  },
  {
    id: 'b69db152-5aee-48b1-a4d9-72efbfb067cc',
    uri: `/${appNames.budget}/haushalt/${householdId}/analyse/ffg/finanznote/slider`,
  },
  {
    id: 'bb79598f-061f-42eb-bfcc-1acb265c3e09',
    uri: `/${appNames.budget}/haushalt/${householdId}/analyse/ffg/finanznote/zusammenfassung`,
  },
  {
    id: '02f5a819-cf3a-4598-b53e-87f9d21d19dc',
    uri: `/${appNames.vpd}/haushalt/${householdId}/analyse/terminabschluss/bewertung-davor`,
  },
  {
    id: 'd0633c57-4063-40b7-bb2e-1a6299e1549c',
    uri: `/${appNames.vpd}/haushalt/${householdId}/analyse/terminabschluss/bewertung-danach`,
  },
  {
    id: '8ec2221e-7260-4e8a-91b0-5f1a1e47dfaf',
    uri: `/${appNames.vpd}/haushalt/${householdId}/analyse/terminabschluss/empfehlung-und-anwerbung/wuensche-und-empfehlungen`,
  },
  {
    id: '6c146098-7ccb-49db-8f6f-823ade449188',
    uri: `/${appNames.vpd}/haushalt/${householdId}/analyse/terminabschluss/fuenf-schritte`,
  },
  {
    id: 'a20121cc-7f39-425f-bae5-fe33d20872a4',
    uri: `/${appNames.budget}/haushalt/${householdId}/analyse/basisdaten/staatliche-rente`,
  },
  {
    id: '5e0a0253-e4d8-4a0d-9729-6e40a0907155',
    uri: `/${appNames.budget}/haushalt/${householdId}/analyse/basisdaten/wohnsituation`,
  },
  {
    id: '5e0a0253-e4d8-4a0d-9729-6e40a0907155',
    uri: `/${appNames.budget}/haushalt/${householdId}/analyse/basisdaten/wohnsituation`,
  },
  {
    id: 'de46e070-de89-4296-9c96-66a021fa88cd',
    uri: `/${appNames.vpd}/haushalt/${householdId}/analyse/gespraechszusammenfassung`,
  },
  {
    id: 'de46e070-de89-4296-9c96-66a021fa88cd',
    uri: `/${appNames.sectionReport}/haushalt/${householdId}/analyse/gespraechszusammenfassung`,
  },
  {
    id: '465a6fbd-1ff6-4a23-93a0-bc2c13701cbd',
    uri: `/${appNames.shoppingCart}/haushalt/${householdId}/zusammenfassung`, // shoppingCart v1
  },
  {
    id: '70cb41e3-0d5d-4233-8698-113e7ae7044c',
    uri: `/${appNames.shoppingCart}/haushalt/${householdId}/cart`, // shoppingCart v2
  },
  {
    id: '87515dd3-ec74-4ae8-b468-085a5ec24876',
    uri: `/${appNames.catalog}/haushalt/${householdId}/beratung/arbeitskraft/2`,
  },
  {
    id: '76653573-45f7-41fb-aa0f-d0030c541a2e',
    uri: `/${appNames.catalog}/haushalt/${householdId}/beratung/arbeitskraft/3`,
  },
  {
    id: 'a2104b1c-9cbc-4811-9d12-05e3e2854e42',
    uri: `/${appNames.catalog}/haushalt/${householdId}/beratung/arbeitskraft/4`,
  },
  {
    id: 'dea971df-29ef-4565-be5b-bc27ded14518',
    uri: `/${appNames.catalog}/haushalt/${householdId}/beratung/arbeitskraft/5`,
  },
  {
    id: '6a7a8ed9-d0c3-4fb0-a910-27cbf3d40f65',
    uri: `/${appNames.catalog}/haushalt/${householdId}/beratung/vasp/2`,
  },
  {
    id: '9c657e94-27ca-4a0e-9910-e82f46bb70ea',
    uri: `/${appNames.catalog}/haushalt/${householdId}/beratung/vasp/3`,
  },
  {
    id: '98d1ccd8-6680-4fbf-a9a6-7847778a4e9d',
    uri: `/${appNames.catalog}/haushalt/${householdId}/beratung/basis-rente/2`,
  },
  {
    id: '30171c6d-e789-4077-92da-14aeb83f1daa',
    uri: `/${appNames.catalog}/haushalt/${householdId}/beratung/basis-rente/3`,
  },
  {
    id: '3e0e1dae-756f-4524-938c-479684ba8d20',
    uri: `/${appNames.catalog}/haushalt/${householdId}/beratung/basis-rente/4`,
  },
  {
    id: '682899f8-0a12-4372-91b0-f58197b2ce26',
    uri: `/${appNames.catalog}/haushalt/${householdId}/beratung/basis-rente/5`,
  },
  {
    id: '2246aa21-ed68-47eb-944c-b0a9f37d9a7e',
    uri: `/${appNames.catalog}/haushalt/${householdId}/beratung/basis-rente/6`,
  },
  {
    id: '7b35f20f-52c6-4a73-ab13-65e74b5e8cf3',
    uri: `/${appNames.catalog}/haushalt/${householdId}/beratung/bausparen/2`,
  },
  {
    id: 'a3c0a1e3-1ce7-4887-803d-71f38153fcf7',
    uri: `/${appNames.catalog}/haushalt/${householdId}/beratung/bausparen/3`,
  },
  {
    id: '2edc95fd-c668-4a4e-8fc9-923fa4b2dd60',
    uri: `/${appNames.catalog}/haushalt/${householdId}/beratung/bausparen/4`,
  },
];
