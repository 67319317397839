import { useGetConsent } from '@dvag/dfs-ui-blocks/hooks';
import { useCallback, useMemo } from 'react';

import { errorAlert } from '../alert/AlertComponent';
import { getToken } from '../graphql/getGraphQLClient';
import { getContext } from '../services/api/helpers';
import { navigate } from '../utils/navigation';

export const usePageActionsCallbacks = (householdId: string | undefined) => {
  const { checkHasConsent, checkHasAllAdultsConsent, checkHasAllPersonsBirthday } = useGetConsent(
    householdId,
    getToken,
  );

  const hasConsent = checkHasConsent();
  const hasAllAdultsConsent = checkHasAllAdultsConsent();
  const hasAllPersonsBirthDate = checkHasAllPersonsBirthday();

  const navigateCallback = useCallback(
    (route: string, nextUrl?: string, resetWorkflow?: boolean) =>
      navigate(route, nextUrl, resetWorkflow),
    [],
  );

  const checkHasConsentCallback = useCallback(() => hasConsent, [hasConsent]);
  const checkHasAllAdultsConsentCallback = useCallback(
    () => hasAllAdultsConsent,
    [hasAllAdultsConsent],
  );
  const checkHasAllPersonsBirthdayCallback = useCallback(
    () => hasAllPersonsBirthDate,
    [hasAllPersonsBirthDate],
  );
  const errorAlertCallback = useCallback((message: string, title?: string) => {
    const sidebar = document.querySelector('#shopping-cart-widget') as HTMLDxHeaderMenuItemElement;
    if (sidebar) {
      sidebar.closeSidebar();
    }
    errorAlert(message, title);
  }, []);
  const userPermissions = useMemo(() => getContext().user.permissions, []);

  return {
    navigateCallback,
    checkHasConsentCallback,
    checkHasAllAdultsConsentCallback,
    checkHasAllPersonsBirthdayCallback,
    errorAlertCallback,
    userPermissions,
  };
};
