import { appNames } from '@dvag/dfs-constant-config';

import { getSessionStore, sessionStoreKeys } from '../sessionStore';

const removePageTitle = () => {
  getSessionStore(sessionStoreKeys.pageTitle).removeItem();
};

export const routeHelper = (() => {
  let currentRoute: string;
  const handlers: { (route: string): void }[] = [];
  const handleRouteChange = () => {
    handlers.forEach((handler) => handler(currentRoute));
  };
  const helperListener = (evt: MessageEvent) => {
    if (evt.data.msg) {
      if (evt.data.msg === 'luigi.init.ok') {
        removePageTitle();
      }
      if (evt.data.msg === 'luigi.init.ok' || evt.data.msg === 'luigi.navigate.ok') {
        setTimeout(() => {
          const newRoute = window.location.pathname;
          if (newRoute !== currentRoute) {
            currentRoute = newRoute;
            handleRouteChange();
          }
        }, 0);
      }
    }
  };

  window.addEventListener('message', helperListener);
  window.addEventListener('unload', () => window.removeEventListener('message', helperListener));

  const getCurrentRoute = () => currentRoute;
  const addRouteChangeHandler = (handler: (route: string) => void) => handlers.push(handler);

  return { getCurrentRoute, addRouteChangeHandler };
})();

export const isSimulationRoute = (route: string | null) =>
  route && route.toLowerCase().startsWith(`/${appNames.budget}/simulation`);
