import { DxAlert, DxButton } from '@dvag/design-system-react';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';

import type { AlertComponentProps } from '../type/alert';

export const AlertComponent = (props: AlertComponentProps) => {
  const [alertData, setAlertData] = useState<AlertComponentProps | null>(props);

  useEffect(() => {
    setAlertData(props);
  }, [props]);

  if (!alertData) {
    return null;
  }

  const { onConfirm = () => {}, showError, message, title } = alertData;

  return (
    <DxAlert
      id="basic-alert"
      data-testid="basic-alert"
      titlestring={title}
      visible={showError}
      content={message}
    >
      <DxButton
        type="primary-s"
        id="basic-alert-ok-button"
        data-testid="basic-alert-ok-button"
        onClick={() => {
          onConfirm();
          setAlertData(null);
        }}
        slot="action"
        label="Ok"
      />
    </DxAlert>
  );
};

let orchestratorMessageRoot: ReactDOM.Root | undefined;

export const showAlert = (props: AlertComponentProps) => {
  const rootElement = document.getElementById('orchestrator-alert-message') as HTMLElement;
  if (orchestratorMessageRoot === undefined) {
    orchestratorMessageRoot = ReactDOM.createRoot(rootElement);
  }
  orchestratorMessageRoot.render(<AlertComponent {...props} />);
};

export const handleConfirm = () => {
  showAlert({ onConfirm: handleConfirm, showError: false, message: '', title: '' });
};

export const errorAlert = (message: string, title: string | undefined) => {
  showAlert({ onConfirm: handleConfirm, showError: true, message, title });
};
