import APIClient from '@dvag/dfs-api-client';
import { getEnvironmentApiUrls } from '@dvag/dfs-constant-config';

import { PERMISSION } from '../../utils';

import { getToken, getTransformedConfig } from './helpers';

const getHeaderToken = () => {
  const accessToken = getToken();
  return `Bearer ${accessToken}` as const;
};

export const getAdvisorPermissionsService = () => {
  const config = { baseURL: `${getEnvironmentApiUrls().advisor}/v4` };
  const api = new APIClient(getTransformedConfig(config))
    .withResErrorHandling()
    .withReqTokenHeader(getToken)

  api.addRequestInterceptor((cfg) => {
    cfg.headers.set('Authorization', getHeaderToken());
    return cfg;
  });

  const hasAnalysisPermission = async (): Promise<boolean> => {
    try {
      const resp = await api.get(`/advisor/permission/${PERMISSION.canAccessVPD}`);
      return resp.status === 200;
    } catch {
      return false;
    }
  };
  const getUserPermissions = async (): Promise<string[]> => {
    const response = await api.get<string[]>('/advisor/permission/all');
    return response.data;
  };

  return {
    hasAnalysisPermission,
    getUserPermissions,
  };
};
