import { permissions } from '@dvag/dfs-constant-config';
import { useGetFavorites, usePageAction } from '@dvag/dfs-ui-blocks/hooks';
import { useMemo } from 'react';

import { featureFlag } from '../config/featureFlag';
import { getToken } from '../graphql/getGraphQLClient';
import { getContext } from '../services/api/helpers';

import { usePageActionsCallbacks } from './usePageActionsCallbacks';

export const useGetFavoritesWithAction = (householdId?: string) => {
  const newCatalogDashboard = useMemo(() => featureFlag.gb?.isOn('new-catalog-dashboard'), []);

  const pagesQuery = useGetFavorites(
    getToken,
    featureFlag.gb?.isOn(permissions.canAccessNSimulation),
  );

  const {
    navigateCallback,
    checkHasConsentCallback,
    checkHasAllAdultsConsentCallback,
    checkHasAllPersonsBirthdayCallback,
    errorAlertCallback,
    userPermissions,
  } = usePageActionsCallbacks(householdId);

  const favorites = pagesQuery.data;
  const { pageAction } = usePageAction({
    navigate: navigateCallback,
    currentLocation: window.location.pathname,
    hasConsent: checkHasConsentCallback,
    hasAllAdultsConsent: checkHasAllAdultsConsentCallback,
    hasAllPersonsBirthDate: checkHasAllPersonsBirthdayCallback,
    errorToast: errorAlertCallback,
    householdId,
    newCatalogDashboard,
    permission: userPermissions,
    getContext,
    getToken,
  });

  return { data: favorites, onItemClick: pageAction };
};
