import {
  DxButton,
  DxContainer,
  DxGrid,
  DxModal,
  DxSpacer,
  DxText,
  DxTextInput,
} from '@dvag/design-system-react';
import { QueryClientProvider } from '@tanstack/react-query';
import { useState } from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';

import { defaultNS, i18next } from 'config/i18n';
import { queryClient } from 'configuration';

import { SearchResult } from './SearchResult';
import { useSearchAdvisorList } from './useSearchAdvisorList';

let orchestratorMessageRoot: ReactDOM.Root | undefined;

export const ContextSelectionModal = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [advisorId, setAdvisorId] = useState('');
  const [searchUser, setSearchUser] = useState({ firstName: '', lastName: '', advisorId: '' });

  const { data: advisorList } = useSearchAdvisorList(searchUser);

  const isAdvisorIdNumber = (id: string) => {
    const numberId = Number(id);
    return !Number.isNaN(numberId);
  };

  const onSearchUser = () => {
    const searchAdvisorId = isAdvisorIdNumber(advisorId) ? advisorId : '';
    setSearchUser({ firstName, lastName, advisorId: searchAdvisorId });
  };

  const onCloseModal = () => {
    orchestratorMessageRoot?.render(null);
    setFirstName('');
    setLastName('');
    setAdvisorId('');
  };

  const translations = {
    label: i18next.t('contextSelection.label'),
    title: i18next.t('contextSelection.title'),
    firstName: i18next.t('contextSelection.firstName'),
    namePlaceholder: i18next.t('contextSelection.namePlaceholder'),
    lastName: i18next.t('contextSelection.lastName'),
    lastNamePlaceholder: i18next.t('contextSelection.surnamePlaceholder'),
    advisorId: i18next.t('contextSelection.advisorId'),
    advisorIdError: i18next.t('contextSelection.advisorIdValidationError'),
    advisorIdPlaceholder: i18next.t('contextSelection.advisorIdPlaceholder'),
    placeholder: i18next.t('contextSelection.placeholder'),
    cancel: i18next.t('cancel'),
    search: i18next.t('search'),
  };

  return (
    <DxModal
      data-testid="context-selection-modal"
      height="content"
      width="m"
      label={translations.label}
      visible
      onModalClosed={onCloseModal}
    >
      <DxContainer slot="content">
        <DxText type="ps">{translations.title}</DxText>
        <DxSpacer size="16v" />
        <DxGrid mq1="4-4-4">
          <DxTextInput
            data-testid="context-selection-firstName-input"
            id="context-selection-firstName-input"
            label={translations.firstName}
            placeholder={translations.namePlaceholder}
            value={firstName}
            onValueChange={(e) => setFirstName(e.detail)}
          />
          <DxTextInput
            data-testid="context-selection-lastName-input"
            id="context-selection-lastName-input"
            label={translations.lastName}
            placeholder={translations.lastNamePlaceholder}
            value={lastName}
            onValueChange={(e) => setLastName(e.detail)}
          />
          <DxTextInput
            data-testid="context-selection-advisorId-input"
            id="context-selection-advisorId-input"
            label={translations.advisorId}
            placeholder={translations.advisorIdPlaceholder}
            value={advisorId}
            onValueChange={(e) => setAdvisorId(e.detail)}
            errormessage={isAdvisorIdNumber(advisorId) ? '' : translations.advisorIdError}
          />
        </DxGrid>
        <DxSpacer size="16v" />
        {Array.isArray(advisorList) ? (
          <SearchResult selectedUserList={advisorList} searchUser={searchUser} />
        ) : (
          <DxText type="its">{translations.placeholder}</DxText>
        )}
      </DxContainer>
      <DxButton
        data-testid="context-selection-cancel-button"
        slot="secondary-actions"
        type="text"
        label={translations.cancel}
        onClick={onCloseModal}
      />
      <DxButton
        data-testid="context-selection-search-button"
        slot="primary-actions"
        type="primary-m"
        label={translations.search}
        onClick={onSearchUser}
      />
    </DxModal>
  );
};

export const showContextSelectionModal = (): void => {
  const rootElement = document.getElementById('orchestrator-alert-message') as HTMLElement;
  if (orchestratorMessageRoot === undefined) {
    orchestratorMessageRoot = ReactDOM.createRoot(rootElement);
  }

  orchestratorMessageRoot.render(
    <I18nextProvider i18n={i18next} defaultNS={defaultNS}>
      <QueryClientProvider client={queryClient}>
        <ContextSelectionModal />
      </QueryClientProvider>
    </I18nextProvider>,
  );
};
