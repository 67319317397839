import { useState, useEffect } from 'react';

import { routeHelper } from '../services/helpers/routeHelper';

export const useCurrentRoute = () => {
  const [currentRoute, setCurrentRoute] = useState(routeHelper.getCurrentRoute());

  useEffect(() => {
    routeHelper.addRouteChangeHandler((route) => setCurrentRoute(route));
  }, []);

  return currentRoute;
};
