import { initFeatureFlag } from 'config/featureFlag';
import { registerTokenAutoRefresh } from 'services/tokenAutoRefresh';

import { orchestrator } from '../orchestrator';
import { getSessionStore, sessionStoreKeys } from '../services/sessionStore';
import { watchRouteAndExitSimulation } from '../services/watchRouteAndExitSimulation';

import { renderNavigation } from './navigationProvider';

export const lifecycleHooks = {
  luigiAfterInit: () => {
    const changeUserContextSessionStore = getSessionStore<boolean>(
      sessionStoreKeys.changeUserContext,
    );
    const changeUserContext = changeUserContextSessionStore.getItem();
    changeUserContextSessionStore.removeItem();

    if (changeUserContext) {
      orchestrator().auth().login();
    } else {
      registerTokenAutoRefresh();
      renderNavigation();
      initFeatureFlag();
      watchRouteAndExitSimulation();
    }
  },
};
