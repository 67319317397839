import pagesResourcesDe from '@dvag/dfs-constant-config/translation/de';
import pagesResourcesEn from '@dvag/dfs-constant-config/translation/en';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import de from './locales/de.json';
import en from './locales/en.json';

export const locales = {
  german: 'de',
  english: 'en',
};

export const resources = {
  en: {
    orchestrator: en,
    pages: pagesResourcesEn,
  },
  de: {
    orchestrator: de,
    pages: pagesResourcesDe,
  },
} as const;

export const defaultNS = 'orchestrator';

const options = {
  ns: ['orchestrator', 'pages'],
  resources,
  defaultNS,
  fallbackLng: locales.german,
  detection: {
    order: ['navigator'],
  },
  interpolation: {
    escapeValue: false,
  },
  debug: false,
};

export const i18next = i18n.createInstance(options);

export const initI18Next = () => i18next.use(LanguageDetector).use(initReactI18next).init();
