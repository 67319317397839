import APIClient from '@dvag/dfs-api-client';
import { getEnvironmentApiUrls } from '@dvag/dfs-constant-config';

import { getContext, getToken, getTransformedConfig } from './helpers';

import type { OrchestratorContext } from '@dvag/dfs-constant-config/interface/orchestrator-context';
import type { User } from 'type/user';

export const UserRoles = {
  external: 'AUSSENDIENST',
  advisor: 'MITBENUTZER',
  assistant: 'ASSISTENT',
  internalUser: 'INNENDIENST',
};

export const removeParentUserId = (context: OrchestratorContext): OrchestratorContext => ({
  ...context,
  user: { ...context.user, parentUserId: null },
});

export const getUserService = () => {
  const config = { baseURL: `${getEnvironmentApiUrls().advisor}/v4` };
  const getContextFn = () => removeParentUserId(getContext());
  const api = new APIClient(getTransformedConfig(config))
    .withResErrorHandling()
    .withReqTokenHeader(getToken)
    .withReqParentUserIdHeader(() => {
      const ctx = getContextFn();
      return ctx.user.parentUserId!;
    });

  const getUser = async (): Promise<User> => {
    const response = await api.get<User>('/user');
    const advisorInfo = response.data;
    return advisorInfo.type !== UserRoles.advisor
      ? { ...advisorInfo, supervisorId: null }
      : advisorInfo;
  };

  return {
    getUser,
  };
};
