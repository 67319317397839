import { orchestratorMessageKeys } from '@dvag/dfs-constant-config';
import { refetchCartItemList, updateCheckoutState } from '@dvag/dfs-shopping-cart-widget';

import { getShoppingCartItems } from 'component/shopping-cart/getShoppingCartItems';
import { checkAndRenewTokens } from 'services/tokenRenew';

import { showMessage as notificationShowMessage } from '../notification';
import { orchestrator } from '../orchestrator';
import { getHouseholdId } from '../services/helpers/getHouseholdId';
import { getLocalStore, localStoreKeys } from '../services/localStore';
import { getSessionStore, sessionStoreKeys } from '../services/sessionStore';

import { getMicroApplicationList, navigation } from './navigation';

import type { SendCustomMessagePayload, MessageType } from '../notification';
import type { NavigationType } from '../type/navigation';

export const getCurrentMicroappPath = () => window.location.pathname + window.location.search;

type LocalStoreType = Record<string, { previousUrl?: string; nextUrl?: string } | undefined>;
// TODO: Move to orchestrator-client
type ShowMessage = {
  title: string;
  description: string;
  type: MessageType;
  displayType?: 'toast' | 'modal';
};

const microAppNavListLocalStore = getLocalStore<LocalStoreType>(
  localStoreKeys.microAppNavigationList,
);

export const preventUnload = (e: BeforeUnloadEvent) => {
  e.preventDefault();
  e.returnValue = '';
};

const getCurrentMicroApp = (url: string) => url.split('/')[1];

export const microAppNavigate = ({
  url,
  nextUrl,
  navigate = true,
  resetWorkflow = false,
}: NavigationType) => {
  const microApp = getCurrentMicroApp(url);
  const currentMicroApp = getCurrentMicroApp(window.location.pathname);

  const workflow = microAppNavListLocalStore.getItem();
  if (resetWorkflow && workflow) {
    microAppNavListLocalStore.removeItem();
  }

  if (!resetWorkflow && nextUrl && microApp && currentMicroApp !== microApp) {
    microAppNavListLocalStore.extendItem({
      [microApp]: {
        previousUrl: getCurrentMicroappPath(),
        nextUrl,
      },
    });
  }

  const householdId = getHouseholdId(window.location.href);
  if (householdId) {
    const houseHoldSessionStore = getSessionStore(sessionStoreKeys.householdId);
    houseHoldSessionStore.setItem(householdId);
  }

  getShoppingCartItems(currentMicroApp, microApp);

  if (navigate) {
    orchestrator().navigation().navigate(url);
  }
};
const microApplicationList = getMicroApplicationList();

const getMicroAppByUrl = (url: string): keyof typeof microApplicationList | undefined =>
  Object.entries(microApplicationList).find((entries) =>
    url.startsWith(entries[1].url),
  )?.[0] as keyof typeof microApplicationList;

const isExternalMicroApp = (url: string) =>
  Object.values(navigation.nodes()).find((item) => item.pathSegment === getCurrentMicroApp(url))
    ?.isExternal;

const validateHouseholdUrl = (fromUrl: string, toUrl: string) => {
  if (isExternalMicroApp(fromUrl) || isExternalMicroApp(toUrl)) return true;
  const fromHouseholdId = getHouseholdId(fromUrl);
  const toHouseholdId = getHouseholdId(toUrl);
  return fromHouseholdId === toHouseholdId;
};

export function sendCustomMessage<T>(payload: SendCustomMessagePayload & T) {
  return orchestrator().customMessages().sendToAll(payload);
}

const navigateToUrl = (
  fallbackUrl: string,
  getUrl: (appNav: { nextUrl?: string; previousUrl?: string }) => string | undefined,
) => {
  const currentUrl = window.location.pathname;
  const microAppNavigation = microAppNavListLocalStore.getItem()?.[getCurrentMicroApp(currentUrl)];
  if (microAppNavigation) {
    let url = getUrl(microAppNavigation);
    if (url) {
      const nextUrlMicroApp = getMicroAppByUrl(url);

      if (nextUrlMicroApp) {
        const nextUrlPath = url.split(getMicroApplicationList()[nextUrlMicroApp].url)[1];
        url = `/${nextUrlMicroApp}${nextUrlPath.startsWith('/') ? nextUrlPath : `/${nextUrlPath}`}`;
      }

      if (validateHouseholdUrl(currentUrl, url)) {
        orchestrator().navigation().navigate(url);
        return true;
      }
    }
  }
  if (fallbackUrl) {
    orchestrator().navigation().navigate(fallbackUrl);
    return true;
  }
  return false;
};

const {
  login,
  logout,
  navigateToNewWindow,
  setMicroAppIsDirty,
  setMicroAppPreserveIsDirty,
  navigate,
  navigateNext,
  navigatePrevious,
  showMessage,
  setRefetchPersonList,
  receivePageTitle,
  tokenExchangeInit,
  tokenExchangeReady,
} = orchestratorMessageKeys;

export const communication = {
  customMessagesListeners: {
    [login]: () => orchestrator().auth().login(),
    [logout]: () => {
      console.warn('lg 4');
      orchestrator().auth().logout();
    },
    [navigateToNewWindow]: ({ microAppId, path }: { microAppId: string; path: string }) => {
      const currentMicroAppId =
        microAppId ||
        orchestrator().elements().getCurrentMicrofrontendIframe()?.luigi?.currentNode.viewGroup;
      window.open(`${document.location.origin}/${currentMicroAppId}${path}`);
    },
    [setMicroAppIsDirty]: ({ isDirty }: { isDirty: boolean }) => {
      const isDirtySessionStore = getSessionStore(sessionStoreKeys.microappIsDirty);
      window.removeEventListener('beforeunload', preventUnload);
      isDirtySessionStore.removeItem();
      if (isDirty) {
        window.addEventListener('beforeunload', preventUnload);
        isDirtySessionStore.setItem(isDirty);
      }
    },
    [setMicroAppPreserveIsDirty]: () => {
      const isDirtySessionStore = getSessionStore(sessionStoreKeys.preserveIsDirty);
      isDirtySessionStore.setItem(true);
    },
    [navigate]: (payload: NavigationType) => microAppNavigate(payload),
    [navigateNext]: ({
      fallbackUrl,
      options,
    }: {
      fallbackUrl: string;
      options?: {
        resetWorkflow: boolean;
      };
    }) => {
      navigateToUrl(fallbackUrl, (microAppNavigation) => microAppNavigation.nextUrl);
      if (options?.resetWorkflow) {
        microAppNavListLocalStore.removeItem();
      }
    },
    [navigatePrevious]: ({ fallbackUrl }: { fallbackUrl: string }) => {
      if (!navigateToUrl(fallbackUrl, (microAppNavigation) => microAppNavigation.previousUrl)) {
        orchestrator().navigation().goBack();
      }
    },
    [showMessage]: ({ title, description, type = 'error', displayType = 'toast' }: ShowMessage) => {
      notificationShowMessage(title, description, type, displayType);
    },
    [setRefetchPersonList]: () => {
      const fetchPersonListSessionStore = getSessionStore(sessionStoreKeys.fetchPersonList);
      fetchPersonListSessionStore.setItem(Math.random() * 100);
    },
    [receivePageTitle]: ({ title }: { title: string }) => {
      getSessionStore(sessionStoreKeys.pageTitle).setItem({ title });
    },
    [tokenExchangeInit]: ({ targetApp }: { targetApp: string }) => {
      getSessionStore(sessionStoreKeys.tokenExchange).setItem({ targetApp });
      checkAndRenewTokens(() => {
        sendCustomMessage({ id: tokenExchangeReady });
      });
    },
    [orchestratorMessageKeys.updateWidgetCartItemList]: async ({
      householdId,
      cartId,
    }: {
      cartId: string;
      householdId: string;
    }) => {
      await refetchCartItemList(householdId, cartId);
    },
    [orchestratorMessageKeys.updateWidgetCheckoutLoading]: async ({
      loading,
    }: {
      loading: boolean;
    }) => {
      updateCheckoutState(loading);
    },
  },
};
