import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { QueryClientProvider } from '@tanstack/react-query';
import * as ReactDOM from 'react-dom/client';

import { queryClient } from '../configuration';
import { getAppInsights } from '../services/helpers/getAppInsights';

import WrappedNavigation from './WrappedNavigation';

export const NavigationProvider = () => (
  <AppInsightsContext.Provider value={getAppInsights().reactPlugin}>
    <QueryClientProvider client={queryClient}>
      <WrappedNavigation />
    </QueryClientProvider>
  </AppInsightsContext.Provider>
);

export const renderNavigation = () => {
  const rootElement = document.getElementById('orchestrator-navigation');
  if (!rootElement) throw new Error('Failed to find the root element');
  const root = ReactDOM.createRoot(rootElement);
  root.render(<NavigationProvider />);
};
