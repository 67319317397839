import { DxContainer, DxText } from '@dvag/design-system-react';
import { Favorites, useFavoritesSearch } from '@dvag/dfs-ui-blocks/components';

import { i18next } from 'config/i18n';

import SearchInput from './component/SearchInput/SearchInput';

import type { PageCodes } from '@dvag/dfs-constant-config';
import type { FavoritePage } from '@dvag/dfs-ui-blocks/components';

import './style.css';

export const FavoritesDrawerWithSearch = ({
  isEditMode,
  favorites,
  onItemMove,
  onItemRemove,
  onItemClick
}: {
  isEditMode: boolean;
  favorites: FavoritePage[];
  onItemMove: (from: number, to: number) => void;
  onItemRemove: (index: number) => void;
  onItemClick: (code: PageCodes) => void;
}) => {
  const { filteredFavorites, setSearchValue, searchValue } = useFavoritesSearch(
    favorites,
    isEditMode,
    (code: PageCodes) => favorites.find((p) => p.code === code)?.aliases || [],
  );

  return (
    <>
      <DxContainer color="transparent">
        <SearchInput searchValue={searchValue} isDisabled={isEditMode} onSearch={setSearchValue} />
      </DxContainer>
      <DxContainer color="transparent" className="favorites-items-container">
        {filteredFavorites?.length ? (
          <Favorites
            rootElementId="orchestrator-navigation"
            data={filteredFavorites}
            editMode={isEditMode}
            onItemMove={onItemMove}
            onItemRemove={onItemRemove}
            onItemClick={onItemClick}
            canMoveItems
          />
        ) : (
          <DxContainer
            color="transparent"
            className="no-items-container"
            data-testid="no-items-container"
          >
            <DxText type="ps" color="gray-66">
              {i18next.t('favorites.noItems')}
            </DxText>
          </DxContainer>
        )}
      </DxContainer>
    </>
  );
};
