import { appNames } from '@dvag/dfs-constant-config/app-names';
import { ShoppingCart } from '@dvag/dfs-shopping-cart-widget';

import { errorAlert } from 'alert/AlertComponent';

import type { FC } from 'react';
import type { NavigationType } from 'type/navigation';

interface Props {
  householdId: string;
  navigate: ({ url, nextUrl, navigate, resetWorkflow }: NavigationType) => void;
  slot?: string;
  isHidden?: boolean;
}

const ShoppingCartWidget: FC<Props> = ({
  householdId,
  navigate,
  slot = 'shopping-cart',
  isHidden = true,
}) => {
  const headerMenuItem: HTMLDxHeaderMenuItemElement | null = document.querySelector('#favoriten');
  headerMenuItem?.closeSidebar();

  const navigateFunc = (url: string, nextUrl?: string | undefined, resetWorkflow?: boolean) => {
    navigate({ url, nextUrl, resetWorkflow });
    headerMenuItem?.closeSidebar();
  };

  const handleClick = () => {
    navigate({ url: `/${appNames.shoppingCart}/haushalt/${householdId}/zusammenfassung` });
    const sidebar = document.querySelector('#shopping-cart-widget') as HTMLDxHeaderMenuItemElement;
    if (sidebar) {
      sidebar.closeSidebar();
    }
  };

  return (
    <ShoppingCart
      householdId={householdId}
      navigate={navigateFunc}
      slot={slot}
      isHidden={isHidden}
      handleClick={handleClick}
      showAlert={errorAlert}
    />
  );
};
export default ShoppingCartWidget;
