import { onlineManager } from '@tanstack/react-query';

import { checkAndRenewTokens } from './tokenRenew';

export const startAutoRefresh = (onAutorefresh: () => void) => {
  onAutorefresh();
  return setInterval(() => {
    onAutorefresh();
  }, 30000);
};

export const registerAutoRefresh = (onAutorefresh: () => void) => {
  let timeout = startAutoRefresh(onAutorefresh);

  const restartAutoRefresh = (timeoutParam?: NodeJS.Timeout) => {
    if (timeoutParam) {
      clearTimeout(timeoutParam);
    }
    return startAutoRefresh(onAutorefresh);
  };

  document.addEventListener('visibilitychange', () => {
    if (!document.hidden) {
      timeout = restartAutoRefresh(timeout);
    }
  });

  onlineManager.subscribe(() => {
    if (onlineManager.isOnline()) {
      timeout = restartAutoRefresh(timeout);
    }
  });
};

export const registerTokenAutoRefresh = () => {
  registerAutoRefresh(() => {
    if (onlineManager.isOnline()) {
      checkAndRenewTokens();
    } else {
      console.warn('App is offline, token refresh is not possible');
    }
  });
};
