import { getEnvironmentAppUrls } from '@dvag/dfs-constant-config';

import { microAppNavigate } from '../config/communication';
import { orchestrator } from '../orchestrator';
import { getSessionStore, sessionStoreKeys } from '../services/sessionStore';

import type { NavigationType } from '../type/navigation';

export type ChangeUser = {
  userId?: string;
};
export const navigationRequest = (navigationPayload: NavigationType) => {
  orchestrator().customMessages().sendToAll({
    id: 'navigation.request',
    payload: navigationPayload,
  });
};

const envAppUrls = getEnvironmentAppUrls();

export const externalLink = {
  datenturbo: envAppUrls.datenturbo,
  companyPresentation: envAppUrls.companyPresentation,
  /* TODO: remove when feedback button is not needed anymore */
  feedback: 'https://de.research.net/r/feedback-digitaler-antrag',
};

const externalLinks = Object.values(externalLink);

export const navigate = (route: string, nextUrl?: string, resetWorkflow = true) => {
  if (externalLinks.includes(route)) {
    window.open(route);
    return;
  }
  const isDirtySessionStore = getSessionStore(sessionStoreKeys.microappIsDirty);
  const navigationPayload = { url: route, nextUrl, resetWorkflow };
  if (isDirtySessionStore.getItem() === true) {
    navigationRequest(navigationPayload);
  } else {
    microAppNavigate(navigationPayload);
  }
};

export const changeUserContext = (userId?: string) => {
  getSessionStore<ChangeUser>(sessionStoreKeys.changeUserContext).setItem({
    userId,
  });

  window.location.href = `/`;
};
