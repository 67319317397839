import { permissions } from '@dvag/dfs-constant-config';
import { useMemo } from 'react';

import { useCurrentNode } from '../useCurrentNode';

type userPermissionsProperty = keyof typeof permissions;
type PermissionMap = Record<userPermissionsProperty, boolean>;

export function checkPermission(permissionList: string[], perm: string) {
  return permissionList?.includes(perm);
}

export function getPermissionMap(permissionList: string[]): PermissionMap {
  const permissionEntries = Object.entries(permissions);

  return <PermissionMap>(
    permissionEntries
      .map(([key, value]) => ({ [key]: checkPermission(permissionList, value) }))
      .reduce((acc, curr) => ({ ...acc, ...curr }), {})
  );
}

export const usePermission = (): PermissionMap => {
  const currentNode = useCurrentNode();

  const userPermissions = currentNode?.context?.userData?.userPermissions;

  return useMemo(() => {
    const permissionList = userPermissions || [];
    return getPermissionMap(permissionList);
  }, [userPermissions]);
};
