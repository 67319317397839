import { getAppInsights } from "./helpers/getAppInsights";

const { appInsights } = getAppInsights();

export const localStoreKeys = {
  featureFlags: 'feature.flags',
  microAppNavigationList: 'microapp.navigation.list',
  advisorSelectionRedirectUrl: 'advisor.selection.redirect.url',
};

export const getLocalStore = <T>(key: string) => {
  const getItem = () => {
    try {
      const storageItem = localStorage.getItem(key);
      return storageItem ? (JSON.parse(storageItem) as T) : null;
    } catch (e) {
      appInsights.trackException({ exception: new Error(`${key} value in localStorage not a valid JSON`), severityLevel: 3 })
    }
    return null;
  };
  const setItem = (value: T) => {
    localStorage.setItem(key, JSON.stringify(value));
  };

  const removeItem = () => localStorage.removeItem(key);

  const extendItem = (value: T) => {
    const existingItem = getItem();
    if (!existingItem) {
      setItem(value);
    } else {
      const newItem = { ...existingItem, ...value };
      setItem(newItem);
    }
  };

  return {
    getItem,
    setItem,
    extendItem,
    removeItem,
  };
};
