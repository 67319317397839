import { DxHeaderMenuItem, DxSidebarContent } from '@dvag/design-system-react';
import { useEffect, useRef, useState } from 'react';
import { useVisibility } from 'reactjs-visibility';

import { checkIcon } from 'utils/utils';

import { FavoritesDrawer } from './FavoritesDrawer';

import type { LegacyRef } from 'react';

export const FavoritesMenu = ({
  isHidden,
  isDisabled,
  householdId,
}: {
  isHidden: boolean;
  isDisabled: boolean;
  householdId?: string;
}) => {
  const [favoritesVisible, setFavoritesVisible] = useState(false);

  const handleChangeVisibility = (visible: boolean) => {
    setFavoritesVisible(visible);
  };

  const options = {};

  const { ref } = useVisibility({
    onChangeVisibility: handleChangeVisibility,
    options,
  });

  const disabledStyle = isDisabled
    ? {
        opacity: '0.6',
        pointerEvents: 'none',
      }
    : {};
  const favoritesStyle = isHidden ? { display: 'none' } : disabledStyle;

  const favoritesMenuItem = useRef<HTMLDxHeaderMenuItemElement>(null);

  const closeFavoritesMenu = () => favoritesMenuItem.current?.closeSidebar();

  useEffect(() => {
    window.addEventListener('popstate', closeFavoritesMenu);
    return () => window.removeEventListener('popstate', closeFavoritesMenu);
  }, []);

  return (
    <>
      <DxHeaderMenuItem
        style={favoritesStyle}
        slot="menu"
        ref={favoritesMenuItem}
        icon={checkIcon('stern-01')}
        connectedslot="favoriten"
        data-testid="favoriten"
        id="favoriten"
        onMenuInteraction={() => {
          setFavoritesVisible((prev) => !prev);
        }}
      />
      <DxSidebarContent slot="favoriten">
        <div
          data-testid="favorites-container"
          ref={ref as unknown as LegacyRef<HTMLDivElement>}
          style={{ height: '100%', visibility: favoritesVisible ? 'visible' : 'hidden' }}
        >
          {favoritesVisible && <FavoritesDrawer householdId={householdId} />}
        </div>
      </DxSidebarContent>
    </>
  );
};
