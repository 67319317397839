import { DxButton, DxCard, DxContainer, DxList, DxListItem } from '@dvag/design-system-react';
import { type FC } from 'react';

import { i18next } from 'config/i18n';
import { getZOBService } from 'services/api/zob';
import { changeUserContext } from 'utils/navigation';

import type { SearchUser, SearchAdvisor, AdvisorAddress } from 'type/advisor';

export type SearchResultProps = {
  selectedUserList: SearchAdvisor[] | undefined;
  searchUser: SearchUser;
};

export const SearchResult: FC<SearchResultProps> = ({ selectedUserList, searchUser }) => {
  if (!selectedUserList?.length) {
    return (
      <DxCard data-testid="context-selection-user-list">
        <DxList size="l">
          <DxListItem
            textalign="left"
            data-testid="context-selection-uno-advisor-found-label"
            label={i18next.t('contextSelection.noAdvisorFound')}
          />
        </DxList>
      </DxCard>
    );
  }
  const fullName = `${searchUser.firstName} ${searchUser.lastName} ${searchUser.advisorId}`;
  const listTitle = i18next.t('contextSelection.selectedUserListTitle', {
    resultNumber: selectedUserList?.length,
    fullName,
  });

  const impersonateAction = async (contextUserId: string) => {
    const createResponse = await getZOBService().createRelation(contextUserId);
    if (createResponse === null) return;
    const assignResponse = await getZOBService().assignProfiles(contextUserId);
    if (assignResponse === null) return;
    changeUserContext(contextUserId);
  };

  const userAddress = (address?: AdvisorAddress) =>
    address ? `${address.street} ${address.zipcode} ${address.city} ${address.country}` : '';

  return (
    <DxCard data-testid="context-selection-user-list">
      <DxList size="l">
        <DxListItem data-testid="context-selection-user-list-label" label={listTitle} />
        {selectedUserList.map((user) => (
          <DxListItem
            textalign="left"
            label={`${user.firstName} ${user.lastName} (${user.id})`}
            sublabel={userAddress(user.address)}
            key={`${user.firstName}-${user.id}`}
          >
            <DxContainer slot="content">
              <DxButton
                onClick={() => impersonateAction(user.id)}
                label={i18next.t('contextSelection.impersonate')}
                type="secondary-s"
                data-testid={`impersonate-button-${user.id}`}
              />
            </DxContainer>
          </DxListItem>
        ))}
      </DxList>
    </DxCard>
  );
};
