import { getApiUrls } from '@dvag/dfs-constant-config';
import { GraphQLClient } from 'graphql-request';

import { getEnvConfig } from 'services/envConfig';

import { orchestrator } from '../orchestrator';
import { getSessionStore, sessionStoreKeys } from '../services/sessionStore';

import type { UserData } from '../type/advisor';

export const getToken = () => {
  const authData = orchestrator()?.auth().store.getAuthData();
  return authData?.accessToken;
};

export const getHeaders = () => {
  const authData = orchestrator()?.auth().store.getAuthData();
  const userData = getSessionStore<UserData>(sessionStoreKeys.userData).getItem();
  return {
    Authorization: `Bearer ${authData?.accessToken}`,
    ...(userData?.parentUserId ? { 'parent-user-id': userData?.parentUserId } : {}),
  };
};

const graphQLUrls = getApiUrls(getEnvConfig()?.env || 'dev').graphQl;

export const getGraphQLClient = (service: keyof typeof graphQLUrls = 'person') => {
  const url = `${graphQLUrls[service]}`;
  return new GraphQLClient(url, { headers: getHeaders() });
};
