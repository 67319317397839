import { useMemo } from 'react';

import { getGraphQLClient } from '../graphql/getGraphQLClient';
import { useGetIdsQuery } from '../graphql/person/generates';

import type { Exact } from '../graphql/person/generates';

const useGetPersonIdList = (householdId?: string, fetchedPersonListKey?: string) => {
  const query = useGetIdsQuery(
    getGraphQLClient(),
    {
      householdId,
      fetchedPersonListKey,
    } as Exact<{
      householdId: string;
      fetchedPersonListKey?: string;
    }>,
    {
      enabled: !!householdId,
      select: (requestResponse) => ({
        personIdList: requestResponse.household.basicData?.personInHouseholdList?.map(
          ({ id }) => id,
        ),
      }),
    },
  );

  return useMemo(
    () => ({
      ...query,
      data: {
        ...query.data,
        personIdList: query.data?.personIdList || [],
      },
    }),
    [query],
  );
};

export default useGetPersonIdList;
