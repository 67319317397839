import { getSessionStore, sessionStoreKeys } from '../services/sessionStore';

import type { UserData } from '../type/advisor';

export const getInitials = () => {
  const userDataLocalStore = getSessionStore<UserData>(sessionStoreKeys.userData);
  const user = userDataLocalStore.getItem()?.assistant || userDataLocalStore.getItem()?.advisor;
  if (user?.firstName && user.lastName)
    return `${user.firstName?.slice(0, 1)}${user.lastName?.slice(0, 1)}`;
  return '';
};

export const getName = () => {
  const userDataLocalStore = getSessionStore<UserData>(sessionStoreKeys.userData);
  const user = userDataLocalStore.getItem()?.assistant || userDataLocalStore.getItem()?.advisor;
  if (user?.firstName && user?.lastName) {
    return `${user.firstName} ${user.lastName}`;
  }
  return '';
};
