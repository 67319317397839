import APIClient from '@dvag/dfs-api-client';
import { getEnvironmentApiUrls } from '@dvag/dfs-constant-config';

import { i18next } from 'config/i18n';
import { errorToast } from 'notification';

import { getAppInsights } from '../helpers/getAppInsights';

import { getContext, getToken } from './helpers';

export const getZOBService = () => {
  const { appInsights } = getAppInsights();
  const config = {
    baseURL: `${getEnvironmentApiUrls().zob}`,
    headers: { 'Content-Type': 'application/json' },
  };
  const api = new APIClient(config)
    .withResErrorHandling()
    .withReqTokenHeader(getToken)
    .withReqParentUserIdHeader(() => {
      const ctx = getContext();
      return ctx.user.parentUserId!;
    })

  const createRelation = async (contextUserId: string) => {
    const userId = getContext().user.id;
    const today = new Date();

    let day: number | string = today.getDate();
    let month: number | string = today.getMonth() + 1;
    const year = today.getFullYear();

    if (day < 10) {
      day = `0${day}`;
    }

    if (month < 10) {
      month = `0${month}`;
    }

    const validUntil = `${day}${month}${year}`;
    try {
      const response = await api.get(
        `/services/zugangsverwaltung/createvertreter/${userId}/${contextUserId}/${validUntil}`,
      );
      return response.data;
    } catch (err) {
      errorToast(i18next.t('toastNotifications.error'));
      appInsights.trackException({ exception: err as Error, severityLevel: 3 });
    }
    return null;
  };

  const assignProfiles = async (contextUserId: string) => {
    const userId = getContext().user.id;
    try {
      const response = await api.put(
        `/resources/benutzerpermissions/${userId}/${contextUserId}/assignprofiles`,
        JSON.stringify(['profil.id.vb-vertreter-impersonifizierung.min']),
      );
      return response.data;
    } catch (err) {
      errorToast(i18next.t('toastNotifications.error'));
      appInsights.trackException({ exception: err as Error, severityLevel: 3 });
    }
    return null;
  };

  return {
    createRelation,
    assignProfiles,
  };
};
