import { QueryClient } from '@tanstack/react-query';

type TError = {
  response: {
    status: number;
  };
  config: {
    url: string;
  };
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      retry: (failureCount, error) =>
        failureCount <= 5 && ![403, 401].includes((error as TError).response?.status),
    },
  },
});
