import { getSectionService } from '../services/api/recap';
import { getPathList } from '../services/helpers/getPathList';

export const markVisitedSection = async (route: string, householdId: string, personId: string) => {
  const pathList = getPathList(householdId);
  const path = pathList.find((x) => x.uri === route);
  if (path) {
    const sectionService = getSectionService();
    await sectionService.markVisitedSection(householdId, personId, path?.id);
  }
};
