import { useState, useCallback, useEffect } from 'react';

import { orchestrator } from '../orchestrator';

import type { NodePropType } from '../type/navigation';

export const useCurrentNode = () => {
  const [currentNode, setCurrentNode] = useState<NodePropType>({});

  const helperListener = useCallback((evt: MessageEvent) => {
    if (evt.data.msg) {
      if (
        [
          'luigi.init.ok',
          'luigi.auth.tokenIssued',
          'luigi.get-context',
          'luigi.navigate.ok',
          'luigi.navigation.back',
        ].includes(evt.data.msg)
      ) {
        setCurrentNode(
          orchestrator().elements().getCurrentMicrofrontendIframe()?.luigi?.currentNode,
        );
      }
    }
  }, []);

  useEffect(() => {
    window.addEventListener('message', helperListener);

    return () => {
      window.removeEventListener('message', helperListener);
    };
  }, [helperListener]);

  return currentNode;
};
