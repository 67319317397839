import APIClient from '@dvag/dfs-api-client';
import { getEnvironmentApiUrls } from '@dvag/dfs-constant-config';

import { getContext, getToken, getTransformedConfig } from './helpers';

import type { VisitedSection, VisitedSectionPayload } from '../../type/recap';

export const getSectionService = () => {
  const config = { baseURL: `${getEnvironmentApiUrls().recap}/v2` };
  const api = new APIClient(getTransformedConfig(config))
    .withResErrorHandling()
    .withReqTokenHeader(getToken)
    .withReqParentUserIdHeader(() => {
      const ctx = getContext();
      return ctx.user.parentUserId!;
    });

  const markVisitedSection = async (householdId: string, personId: string, pathId: string) => {
    const response = await api.put<VisitedSection, VisitedSectionPayload>('/visited-section', {
      householdId,
      personId,
      pathId,
    });
    return response.data;
  };

  return {
    markVisitedSection,
  };
};
