import { appNames, getEnvironmentAppUrls } from '@dvag/dfs-constant-config';

import { getEnvConfig } from 'services/envConfig';
import { getHouseholdId } from 'services/helpers/getHouseholdId';

import { getPermissionMap } from '../navigation/permission/usePermission';
import { orchestrator } from '../orchestrator';
import { getSessionStore, sessionStoreKeys } from '../services/sessionStore';

import { featureFlag } from './featureFlag';

import type { Advisor, UserData } from '../type/advisor';

export const getMicroApplicationList = () => {
  const envAppUrls = getEnvironmentAppUrls();

  return {
    vpd: { id: appNames.vpd, url: getEnvConfig().mfe?.vpd ?? envAppUrls.vpd },
    accounting: {
      id: appNames.budget,
      url: getEnvConfig().mfe?.accounting ?? envAppUrls.accounting,
    },
    webvpCatalog: {
      id: appNames.webvpCatalog,
      url: getEnvConfig().mfe?.webvpCatalog ?? envAppUrls.webvpCatalog,
    },
    shoppingCart: {
      id: appNames.shoppingCart,
      url: getEnvConfig().mfe?.shoppingCart ?? envAppUrls.shoppingCart,
    },
    catalog: {
      id: appNames.catalog,
      url: getEnvConfig().mfe?.catalog ?? envAppUrls.catalog,
    },
    login: { id: appNames.login, url: getEnvConfig().mfe?.login ?? envAppUrls.login },
    person: { id: appNames.person, url: getEnvConfig().mfe?.person ?? envAppUrls.person },
    advisor: { id: appNames.advisor, url: getEnvConfig().mfe?.advisor ?? envAppUrls.advisor },
    sectionReport: {
      id: appNames.sectionReport,
      url: getEnvConfig().mfe?.sectionReport ?? envAppUrls.sectionReport,
    },
    supervisorReport: {
      id: appNames.supervisorReport,
      url: getEnvConfig().mfe?.supervisorReport ?? envAppUrls.supervisorReport,
    },
    analysisEvaluation: {
      id: appNames.analysisEvaluation,
      url: getEnvConfig().mfe?.analysisEvaluation ?? envAppUrls.analysisEvaluation,
    },
    generali: { id: appNames.generali, url: getEnvConfig().mfe?.generali ?? envAppUrls.generali },
    kontoeroeffnung: {
      id: appNames.kontoeroeffnung,
      url: getEnvConfig().mfe?.kontoeroeffnung ?? envAppUrls.kontoeroeffnung,
    },
    ebike: { id: appNames.ebike, url: getEnvConfig().mfe?.ebike ?? envAppUrls.ebike },
    kranken: { id: appNames.kranken, url: getEnvConfig().mfe?.kranken ?? envAppUrls.kranken },
    rechtsschutz: {
      id: appNames.rechtsschutz,
      url: getEnvConfig().mfe?.rechtsschutz ?? envAppUrls.rechtsschutz,
    },
    investmentanlage: {
      id: appNames.investmentanlage,
      url: getEnvConfig().mfe?.investmentanlage ?? envAppUrls.investmentanlage,
    },
    investmentdialog: {
      id: appNames.investmentdialog,
      url: getEnvConfig().mfe?.investmentdialog ?? envAppUrls.investmentdialog,
    },
    kfz: { id: appNames.kfz, url: getEnvConfig().mfe?.kfz ?? envAppUrls.kfz },
    leben: { id: appNames.leben, url: getEnvConfig().mfe?.leben ?? envAppUrls.leben },
    referenz: { id: appNames.referenz, url: getEnvConfig().mfe?.referenz ?? envAppUrls.referenz },
    checkout: {
      id: appNames.checkout,
      url: getEnvConfig().mfe?.dvagCheckout ?? envAppUrls.dvagCheckout,
    },
    gkv: {
      id: appNames.gkv,
      url: getEnvConfig().mfe?.gkv ?? envAppUrls.gkv,
    },
  };
};

const getContext = () => ({
  contextUpdateTime: new Date().toLocaleTimeString(),
  exchangedToken: getSessionStore(sessionStoreKeys.tokenExchange).getItem(),
  // ToDo remove authData from context after notifying external users that maybe using it - like Generali
  // Instead use directly Luigi.getToken() or orchestrator.getToken() method on MFE's
  authData: orchestrator().auth().store.getAuthData(),
  userData: getSessionStore<Advisor>(sessionStoreKeys.userData)?.getItem(),
  featureFlags: featureFlag.getFeatures(),
  permissions: getPermissionMap(
    getSessionStore<UserData>(sessionStoreKeys.userData)?.getItem()?.userPermissions || [],
  ),
  householdId:
    getHouseholdId(window.location.pathname) ||
    getSessionStore<string>(sessionStoreKeys.householdId).getItem(),
});

export const navigation = {
  nodes: () => [
    {
      pathSegment: getMicroApplicationList().advisor.id,
      viewGroup: getMicroApplicationList().advisor.id,
      navigationContext: getMicroApplicationList().advisor.id,
      viewUrl: getMicroApplicationList().advisor.url,
      anonymousAccess: true,
      virtualTree: true,
      context: getContext(),
      loadingIndicator: { enabled: false },
      isExternal: false,
    },
    {
      pathSegment: getMicroApplicationList().person.id,
      viewGroup: getMicroApplicationList().person.id,
      navigationContext: getMicroApplicationList().person.id,
      viewUrl: getMicroApplicationList().person.url,
      anonymousAccess: true,
      virtualTree: true,
      context: getContext(),
      loadingIndicator: { enabled: false },
      isExternal: false,
    },
    {
      pathSegment: getMicroApplicationList().vpd.id,
      viewGroup: getMicroApplicationList().vpd.id,
      navigationContext: getMicroApplicationList().vpd.id,
      viewUrl: getMicroApplicationList().vpd.url,
      anonymousAccess: true,
      virtualTree: true,
      context: getContext(),
      loadingIndicator: { enabled: false },
      isExternal: false,
    },
    {
      pathSegment: getMicroApplicationList().accounting.id,
      viewGroup: getMicroApplicationList().accounting.id,
      navigationContext: getMicroApplicationList().accounting.id,
      viewUrl: getMicroApplicationList().accounting.url,
      anonymousAccess: true,
      virtualTree: true,
      context: getContext(),
      loadingIndicator: { enabled: false },
      isExternal: false,
    },
    {
      pathSegment: getMicroApplicationList().webvpCatalog.id,
      viewGroup: getMicroApplicationList().webvpCatalog.id,
      navigationContext: getMicroApplicationList().webvpCatalog.id,
      viewUrl: getMicroApplicationList().webvpCatalog.url,
      anonymousAccess: true,
      virtualTree: true,
      context: getContext(),
      loadingIndicator: { enabled: false },
      isExternal: false,
    },
    {
      pathSegment: getMicroApplicationList().shoppingCart.id,
      viewGroup: getMicroApplicationList().shoppingCart.id,
      navigationContext: getMicroApplicationList().shoppingCart.id,
      viewUrl: getMicroApplicationList().shoppingCart.url,
      anonymousAccess: true,
      virtualTree: true,
      context: getContext(),
      loadingIndicator: { enabled: false },
      isExternal: false,
    },
    {
      pathSegment: getMicroApplicationList().catalog.id,
      viewGroup: getMicroApplicationList().catalog.id,
      navigationContext: getMicroApplicationList().catalog.id,
      viewUrl: getMicroApplicationList().catalog.url,
      anonymousAccess: true,
      virtualTree: true,
      context: getContext(),
      loadingIndicator: { enabled: false },
      isExternal: false,
    },
    {
      pathSegment: getMicroApplicationList().login.id,
      viewGroup: getMicroApplicationList().login.id,
      navigationContext: getMicroApplicationList().login.id,
      viewUrl: getMicroApplicationList().login.url,
      anonymousAccess: true,
      virtualTree: true,
      context: getContext(),
      loadingIndicator: { enabled: false },
      isExternal: false,
    },
    {
      pathSegment: getMicroApplicationList().sectionReport.id,
      viewGroup: getMicroApplicationList().sectionReport.id,
      navigationContext: getMicroApplicationList().sectionReport.id,
      viewUrl: getMicroApplicationList().sectionReport.url,
      anonymousAccess: true,
      virtualTree: true,
      context: getContext(),
      loadingIndicator: { enabled: false },
      isExternal: false,
    },
    {
      pathSegment: getMicroApplicationList().supervisorReport.id,
      viewGroup: getMicroApplicationList().supervisorReport.id,
      navigationContext: getMicroApplicationList().supervisorReport.id,
      viewUrl: getMicroApplicationList().supervisorReport.url,
      anonymousAccess: true,
      virtualTree: true,
      context: getContext(),
      loadingIndicator: { enabled: false },
      isExternal: false,
    },
    {
      pathSegment: getMicroApplicationList().analysisEvaluation.id,
      viewGroup: getMicroApplicationList().analysisEvaluation.id,
      navigationContext: getMicroApplicationList().analysisEvaluation.id,
      viewUrl: getMicroApplicationList().analysisEvaluation.url,
      anonymousAccess: true,
      virtualTree: true,
      context: getContext(),
      loadingIndicator: { enabled: false },
      isExternal: false,
    },
    {
      pathSegment: getMicroApplicationList().generali.id,
      viewGroup: getMicroApplicationList().generali.id,
      navigationContext: getMicroApplicationList().generali.id,
      viewUrl: getMicroApplicationList().generali.url,
      anonymousAccess: true,
      virtualTree: true,
      context: getContext(),
      loadingIndicator: { enabled: false },
      isExternal: true,
    },
    {
      pathSegment: getMicroApplicationList().kontoeroeffnung.id,
      viewGroup: getMicroApplicationList().kontoeroeffnung.id,
      navigationContext: getMicroApplicationList().kontoeroeffnung.id,
      viewUrl: getMicroApplicationList().kontoeroeffnung.url,
      anonymousAccess: true,
      virtualTree: true,
      context: getContext(),
      loadingIndicator: { enabled: false },
      isExternal: true,
    },
    {
      pathSegment: getMicroApplicationList().ebike.id,
      viewGroup: getMicroApplicationList().ebike.id,
      navigationContext: getMicroApplicationList().ebike.id,
      viewUrl: getMicroApplicationList().ebike.url,
      anonymousAccess: true,
      virtualTree: true,
      context: getContext(),
      loadingIndicator: { enabled: false },
      isExternal: true,
    },
    {
      pathSegment: getMicroApplicationList().kranken.id,
      viewGroup: getMicroApplicationList().kranken.id,
      navigationContext: getMicroApplicationList().kranken.id,
      viewUrl: getMicroApplicationList().kranken.url,
      anonymousAccess: true,
      virtualTree: true,
      context: getContext(),
      loadingIndicator: { enabled: false },
      isExternal: true,
    },
    {
      pathSegment: getMicroApplicationList().rechtsschutz.id,
      viewGroup: getMicroApplicationList().rechtsschutz.id,
      navigationContext: getMicroApplicationList().rechtsschutz.id,
      viewUrl: getMicroApplicationList().rechtsschutz.url,
      anonymousAccess: true,
      virtualTree: true,
      context: getContext(),
      loadingIndicator: { enabled: false },
      isExternal: true,
    },
    {
      pathSegment: getMicroApplicationList().investmentanlage.id,
      viewGroup: getMicroApplicationList().investmentanlage.id,
      navigationContext: getMicroApplicationList().investmentanlage.id,
      viewUrl: getMicroApplicationList().investmentanlage.url,
      anonymousAccess: true,
      virtualTree: true,
      context: getContext(),
      loadingIndicator: { enabled: false },
      isExternal: true,
    },
    {
      pathSegment: getMicroApplicationList().investmentdialog.id,
      viewGroup: getMicroApplicationList().investmentdialog.id,
      navigationContext: getMicroApplicationList().investmentdialog.id,
      viewUrl: getMicroApplicationList().investmentdialog.url,
      anonymousAccess: true,
      virtualTree: true,
      context: getContext(),
      loadingIndicator: { enabled: false },
      isExternal: true,
    },
    {
      pathSegment: getMicroApplicationList().kfz.id,
      viewGroup: getMicroApplicationList().kfz.id,
      navigationContext: getMicroApplicationList().kfz.id,
      viewUrl: getMicroApplicationList().kfz.url,
      anonymousAccess: true,
      virtualTree: true,
      context: getContext(),
      loadingIndicator: { enabled: false },
      isExternal: true,
    },
    {
      pathSegment: getMicroApplicationList().leben.id,
      viewGroup: getMicroApplicationList().leben.id,
      navigationContext: getMicroApplicationList().leben.id,
      viewUrl: getMicroApplicationList().leben.url,
      anonymousAccess: true,
      virtualTree: true,
      context: getContext(),
      loadingIndicator: { enabled: false },
      isExternal: true,
    },
    {
      pathSegment: getMicroApplicationList().referenz.id,
      viewGroup: getMicroApplicationList().referenz.id,
      navigationContext: getMicroApplicationList().referenz.id,
      viewUrl: getMicroApplicationList().referenz.url,
      anonymousAccess: true,
      virtualTree: true,
      context: getContext(),
      loadingIndicator: { enabled: false },
      isExternal: true,
    },
    {
      pathSegment: getMicroApplicationList().gkv.id,
      viewGroup: getMicroApplicationList().gkv.id,
      navigationContext: getMicroApplicationList().gkv.id,
      viewUrl: getMicroApplicationList().gkv.url,
      anonymousAccess: true,
      virtualTree: true,
      context: getContext(),
      loadingIndicator: { enabled: false },
      isExternal: true,
    },
    {
      pathSegment: getMicroApplicationList().checkout.id,
      viewGroup: getMicroApplicationList().checkout.id,
      navigationContext: getMicroApplicationList().checkout.id,
      viewUrl: getMicroApplicationList().checkout.url,
      anonymousAccess: true,
      virtualTree: true,
      context: getContext(),
      loadingIndicator: { enabled: false },
      isExternal: true,
    },
    {
      pathSegment: 'test',
      viewGroup: 'test',
      navigationContext: 'test',
      viewUrl: 'http://localhost:3000/login',
      anonymousAccess: true,
      virtualTree: true,
      context: getContext(),
      loadingIndicator: { enabled: false },
      isExternal: true,
    },
  ],
};
