import { DxContainer, DxInfo, DxPopover, DxText, DxTextInput } from '@dvag/design-system-react';

import { i18next } from 'config/i18n';
import { checkIcon } from 'utils/utils';

import './style.css';

type SearchInputProps = {
  isDisabled: boolean;
  onSearch: (value: string) => void;
  searchValue: string;
};

const SearchInput = ({ isDisabled, onSearch, searchValue }: SearchInputProps) => (
  <DxContainer color="transparent" className="flex">
    <DxTextInput
      size="m"
      placeholder={i18next.t('common.pleaseEnter')}
      value={searchValue}
      data-testid="favorites-search"
      id="favorites-search"
      disabled={isDisabled}
      className="search-bar"
      icon={checkIcon('lupe')}
      onValueChange={(e) => onSearch(e?.detail?.trim())}
      style={{ flex: 1 }}
    />
    <DxInfo size="content" id="info" className="favorites-info-button">
      <DxPopover>
        <DxText>{i18next.t('favorites.information')}</DxText>
      </DxPopover>
    </DxInfo>
  </DxContainer>
);

export default SearchInput;
