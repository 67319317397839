import { getApplicationInsights } from '@dvag/dfs-application-insights-react';
import { getApiUrls } from '@dvag/dfs-constant-config';

import { getEnvConfig } from 'services/envConfig';

export const getAppInsights = () => {
  const connectionString = getApiUrls(getEnvConfig().env).appInsights;
  const { appInsights, reactPlugin } = getApplicationInsights(connectionString, 'orchestrator');
  return { appInsights, reactPlugin };
}
