import {
  defineCustomElements as defineDesignSystemIcons,
  applyPolyfills as designSystemIconsApplyPolyfills,
} from '@dvag/design-system-icons/loader';

import '@luigi-project/core/luigi';
import '@luigi-project/core/luigi.css';
import { i18next, initI18Next } from 'config/i18n';
import { getSessionStore, sessionStoreKeys } from 'services/sessionStore';

import { getAuth } from './config/auth';
import { communication } from './config/communication';
import { navigation } from './config/navigation';
import { routing } from './config/routing';
import { settings } from './config/settings';
import { lifecycleHooks } from './navigation/lifecycle-hooks';
import { orchestrator } from './orchestrator';
import { getLocale } from './services/helpers/locale';

import type { ChangeUser } from 'utils/navigation';

const htmlEl = document.documentElement || document.getElementsByTagName('html')[0];
htmlEl.lang = getLocale();

initI18Next().then(() => {
  orchestrator().ux().setDocumentTitle(i18next.t('documentTitle'));
  const changeUserContext = getSessionStore<ChangeUser>(
    sessionStoreKeys.changeUserContext,
  ).getItem();
  const navigationItem = changeUserContext ? { nodes: () => [] } : navigation;
  orchestrator().setConfig({
    auth: getAuth(changeUserContext),
    communication,
    lifecycleHooks,
    navigation: navigationItem,
    routing,
    settings,
  });
});

designSystemIconsApplyPolyfills().then(() => {
  defineDesignSystemIcons();
});
