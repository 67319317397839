import { getEnvironmentAppUrls } from '@dvag/dfs-constant-config';
import { jwtDecode } from 'jwt-decode';

import { getToken } from 'graphql/getGraphQLClient';
import { orchestrator } from 'orchestrator';

import { getTokenService } from './api/token';
import { getAppInsights } from './helpers/getAppInsights';
import { getSessionStore, sessionStoreKeys } from './sessionStore';

import type { AccessToken, AuthData } from 'type/advisor';

const { appInsights } = getAppInsights();

const getTokenExpirationDate = (token: string) => {
  const tokenData: AccessToken = jwtDecode(JSON.stringify(token));
  const { exp } = tokenData;
  return exp;
};

export const isAuthenticationExpired = (authData: AuthData) => {
  const token: AccessToken = jwtDecode(JSON.stringify(authData.accessToken));
  const now = Math.floor(Date.now() / 1000);
  const expiresIn = token.exp - now;
  console.warn(new Date().toLocaleTimeString(), 'lg token expires in', expiresIn);
  return expiresIn <= 60;
};

export const renewToken = async () => {
  const dvagAuthUrl = getEnvironmentAppUrls().dvagAuth;
  const oidcStorage = `oidc.user:${dvagAuthUrl}/realms/zob:app-web-vp-digital`;
  const oidcSessionStore = getSessionStore<{ refresh_token: string }>(oidcStorage);

  const refreshToken = oidcSessionStore.getItem()?.refresh_token;

  const authData: AuthData = orchestrator().auth().store.getAuthData();

  if (!refreshToken || !authData) {
    return Promise.reject(new Error('lg no refresh token or auth data'));
  }

  return getTokenService()
    .renewToken(refreshToken)
    .then((data) => {
      sessionStorage.setItem(oidcStorage, JSON.stringify(data));

      const newAuthData: AuthData = {
        accessToken: data.access_token,
        idToken: data.id_token,
        scope: data.scope,
        profile: authData.profile,
        accessTokenExpirationDate: getTokenExpirationDate(data.access_token),
      };
      orchestrator().auth().store.setAuthData(newAuthData);
      window.postMessage({ msg: 'luigi.auth.tokenIssued', authData: newAuthData }, window.origin);
      return newAuthData;
    });
};

export const checkAndRenewToken = async () => {
  const authData: AuthData = orchestrator().auth().store.getAuthData();
  if (!authData) {
    return undefined;
  }
  if (isAuthenticationExpired(authData)) {
    return renewToken().catch((err) => {
      appInsights.trackException({ exception: err, severityLevel: 3 });
      orchestrator().auth().logout();
    });
  }
  return undefined;
};

export const renewExchangeToken = async (token: string) => {
  const tokenExchangeStore = getSessionStore<{ targetApp: string; token: string }>(
    sessionStoreKeys.tokenExchange,
  );
  const tokenExchange = tokenExchangeStore.getItem();
  if (tokenExchange?.targetApp) {
    return getTokenService()
      .exchangeToken(token, tokenExchange.targetApp)
      .then((data) => {
        tokenExchangeStore.setItem({
          targetApp: tokenExchange.targetApp,
          token: data.access_token,
        });
        orchestrator().configChanged('navigation.nodes');
      })
      .catch((err) => {
        appInsights.trackException({ exception: err, severityLevel: 3 });
        orchestrator().auth().logout();
      });
  }
  return undefined;
};

export const checkAndRenewTokens = async (onSetExchangeTokenFirstTime?: () => void) => {
  const tokenExchangeStore = getSessionStore<{ targetApp: string; token: string }>(
    sessionStoreKeys.tokenExchange,
  );
  const exchangeTokenNotSet =
    tokenExchangeStore.getItem()?.targetApp && !tokenExchangeStore.getItem()?.token;
  if (exchangeTokenNotSet) {
    renewExchangeToken(getToken()).then(onSetExchangeTokenFirstTime);
  }
  checkAndRenewToken().then((authData) => {
    if (authData) {
      renewExchangeToken(authData.accessToken);
    }
  });
};
