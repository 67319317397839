import APIClient from '@dvag/dfs-api-client';
import { getEnvironmentAppUrls } from '@dvag/dfs-constant-config';

export type RefreshTokenResponse = {
  access_token: string;
  expires_in: number;
  refresh_expires_in: number;
  refresh_token: string;
  token_type: string;
  id_token: string;
  scope: string;
};

export const getTokenService = () => {
  const config = {
    baseURL: getEnvironmentAppUrls().dvagAuth,
  };
  const api = new APIClient(config)

  const renewToken = async (refreshToken: string) => {
    const response = await api.post<RefreshTokenResponse>(
      `/realms/zob/protocol/openid-connect/token`,
      {
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
        client_id: 'app-web-vp-digital',
      },
      { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } },
    );
    return response.data;
  };

  const exchangeToken = async (
    webVpDigitalToken: string,
    targetApp: string,
  ): Promise<{
    access_token: string;
    expires_in: number;
    refresh_expires_in: number;
    refresh_token: string;
    scope: string;
    session_state: string;
    token_type: string;
  }> => {
    const grantType = encodeURIComponent('urn:ietf:params:oauth:grant-type:token-exchange');
    const requestedTokenType = encodeURIComponent('urn:ietf:params:oauth:token-type:refresh_token');
    const dvagAuthUrl = getEnvironmentAppUrls().dvagAuth;
    const response = await fetch(`${dvagAuthUrl}/realms/zob/protocol/openid-connect/token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `client_id=app-web-vp-digital&grant_type=${grantType}&subject_token=${webVpDigitalToken}&requested_token_type=${requestedTokenType}&audience=${targetApp}`,
    });
    const payload = await response.json();
    return payload;
  };

  return {
    renewToken,
    exchangeToken,
  };
};
