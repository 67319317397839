import { i18next } from 'config/i18n';

import { defaultToast } from '../notification';

import { isSimulationRoute, routeHelper } from './helpers/routeHelper';
import { getSessionStore, sessionStoreKeys } from './sessionStore';

const routeChangeHandler = async (currentRoute: string) => {
  if (getSessionStore(sessionStoreKeys.preserveIsDirty).getItem()) {
    getSessionStore(sessionStoreKeys.preserveIsDirty).removeItem();
  } else {
    getSessionStore(sessionStoreKeys.microappIsDirty).removeItem();
  }
  const previousPage = getSessionStore<string>(sessionStoreKeys.previousPage).getItem();
  const simulationExit = !isSimulationRoute(currentRoute) && isSimulationRoute(previousPage);
  if (simulationExit) {
    defaultToast(i18next.t('toastNotifications.exitSimulation'));
  }
  getSessionStore(sessionStoreKeys.previousPage).setItem(currentRoute);
};

export const watchRouteAndExitSimulation = () => {
  routeHelper.addRouteChangeHandler((route) => routeChangeHandler(route));
};
