import { useQuery } from '@tanstack/react-query';

import { getAdvisorService } from 'services/api/advisor';

import type { SearchUser } from 'type/advisor';

export const useSearchAdvisorList = ({ firstName, lastName, advisorId }: SearchUser) => {
  const advisorService = getAdvisorService();
  return useQuery({
    queryKey: [`search-advisor-list`, firstName, lastName, advisorId],
    queryFn: async () => {
      const searchParams = {
        ...(firstName && { firstName }),
        ...(lastName && { lastName }),
        ...(advisorId && { advisorId }),
      };
      return advisorService.searchAdvisorList(searchParams);
    },
    enabled: firstName.length > 0 || lastName.length > 0 || advisorId.length > 0,
  });
};
