import { orchestrator } from '../../orchestrator';

import type { RequestConfig } from './types';
import type { OrchestratorContext } from '@dvag/dfs-constant-config/interface/orchestrator-context';

export const getTransformedConfig = (config: RequestConfig) => {
  const requestConfig = { ...config };
  if (!requestConfig.headers) {
    requestConfig.headers = {};
  }
  const advisorId = orchestrator().auth().store.getAuthData().profile.preferred_username;
  if (advisorId) {
    requestConfig.headers['ADVISOR-ID'] = advisorId;
  }
  return requestConfig;
};

export const getToken = () => orchestrator().auth().store.getAuthData().accessToken;

export const getContext = (parentUserId?: string | null): OrchestratorContext => {
  const { context } = orchestrator().config.navigation.nodes()[0];

  return {
    ...context,
    user: {
      id: context.authData?.profile.preferred_username,
      name: context.authData?.profile.name,
      parentUserId: parentUserId || context.userData?.parentUserId,
      role: context.userData?.role,
      permissions: context.userData?.userPermissions,
      advisor: context.userData?.advisor,
      assistant: context.userData?.assistant,
    },
  } as unknown as OrchestratorContext;
};
