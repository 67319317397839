import { refetchCart } from '@dvag/dfs-shopping-cart-widget';

import { navigation } from 'config/navigation';

export function getShoppingCartItems(currentApp: string, nextApp: string) {
  const isNextMicroAppExternal = Object.values(navigation.nodes()).find(
    (item) => item.pathSegment === nextApp,
  )?.isExternal;
  const isCurrentMicroAppExternal = Object.values(navigation.nodes()).find(
    (item) => item.pathSegment === currentApp,
  )?.isExternal;

  const currentMfeIsExternal = !!isCurrentMicroAppExternal;
  const nextMfeIsExternal = !!isNextMicroAppExternal;
  if (currentMfeIsExternal || nextMfeIsExternal) {
    refetchCart();
  }
}
