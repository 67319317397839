import { useMemo } from 'react';

import { FavoritesMenu } from '../component/FavoritesDrawer/FavoritesMenu';
import ShoppingCartWidget from '../component/shopping-cart';
import { microAppNavigate } from '../config/communication';

export const MenuItemList = ({
  isFavoritesHidden,
  isFavoritesDisabled,
  householdId,
  isShoppingCartHidden,
}: {
  isFavoritesHidden: boolean;
  isFavoritesDisabled: boolean;
  householdId: string | undefined;
  isShoppingCartHidden: boolean;
}) =>
  useMemo(
    () => (
      <>
        <FavoritesMenu
          isHidden={isFavoritesHidden}
          isDisabled={isFavoritesDisabled}
          householdId={householdId}
        />
        <ShoppingCartWidget
          slot="shopping-cart"
          householdId={householdId as string}
          navigate={microAppNavigate}
          isHidden={isShoppingCartHidden}
        />
      </>
    ),
    [householdId, isFavoritesDisabled, isFavoritesHidden, isShoppingCartHidden],
  );
