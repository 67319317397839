import { DxNotificationToast, DxText } from '@dvag/design-system-react';
import ReactDOM from 'react-dom/client';

import { errorAlert } from '../alert/AlertComponent';

export type MessageType = 'error' | 'success' | 'default';
export type NotificationProps = { title: string; description: string; type: MessageType};
export type SendCustomMessagePayload = { id: string };

const CLOSE_TIMEOUT = 5;

const NotificationComponent = ({ title = ' ', description, type }: NotificationProps) => {
  const textStyle = title === ' ' ? { height: '80%', display: 'flex', alignItems: 'center' } : {};
  return (
    <DxNotificationToast
      id="notification-component"
      data-testid="notification-component"
      visible={!!title}
      closeafter={CLOSE_TIMEOUT}
      type={type}
      titlestring={title}
    >
      <div style={textStyle}>
        <DxText id="notification-text" data-testid="notification-text" color="gray-83">
          {description}
        </DxText>
      </div>
    </DxNotificationToast>
  );
};

let orchestratorMessageRoot: ReactDOM.Root | undefined;
export const showMessage = (title: string, description: string, type: MessageType, displayType: "toast" | "modal" = "toast") => {
  if (displayType === 'modal') {
    errorAlert(description, title);
  } else {
    const rootElement = document.getElementById('orchestrator-message') as HTMLElement;
    if (orchestratorMessageRoot === undefined) {
      orchestratorMessageRoot = ReactDOM.createRoot(rootElement);
    }
    orchestratorMessageRoot.render(
      <NotificationComponent title={title} description={description} type={type} />,
    );
  }
};

export const successToast = (message: string) => showMessage(' ', message, 'success');

export const errorToast = (message: string) => showMessage(' ', message, 'error');

export const defaultToast = (message: string) => showMessage(' ', message, 'default');
