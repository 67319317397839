import { Type, useGetConfigBurgerMenuQuery } from 'graphql/configuration/generates';
import { getGraphQLClient } from 'graphql/getGraphQLClient';

export function useGetConfigBurgerMenu() {
  return useGetConfigBurgerMenuQuery(
    getGraphQLClient('configuration'),
    { type: Type.BurgerMenu },
    { select: (response) => response.config.pages || [] },
  );
}
