import { appNames } from '@dvag/dfs-constant-config';

import { i18next } from 'config/i18n';

import { navigate } from '../utils/navigation';

export const getBottomLinks = () => [
  {
    id: 'privacy-policy-link',
    text: i18next.t('bottomLinks.privacyPolicy'),
    action: () =>
      window.open('https://www.dvag.de/dvag/datenschutz.html', 'Privacy Policy', 'noopener'),
  },
  {
    id: 'imprint-link',
    text: i18next.t('bottomLinks.imprint'),
    action: () => window.open('https://www.dvag.de/dvag/impressum.html', 'Imprint', 'noopener'),
  },
  {
    id: 'terms-of-use-link',
    text: i18next.t('bottomLinks.termsOfUse'),
    action: () => navigate(`/${appNames.vpd}/nutzungsbedingungen`),
  },
];
