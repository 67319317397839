import APIClient from '@dvag/dfs-api-client';
import { getEnvironmentApiUrls } from '@dvag/dfs-constant-config';

import { i18next } from 'config/i18n';
import { errorToast } from 'notification';

import { getAppInsights } from '../helpers/getAppInsights';

import { getContext, getToken, getTransformedConfig } from './helpers';

import type { Advisor, Assistant, SearchAdvisor, SearchUser } from 'type/advisor';

const { appInsights } = getAppInsights();

export const getAdvisorService = (parentUserId?: string | null) => {
  const config = { baseURL: `${getEnvironmentApiUrls().advisor}/v4` };
  const api = new APIClient(getTransformedConfig(config))
    .withResErrorHandling()
    .withReqTokenHeader(getToken)
    .withReqParentUserIdHeader(() => {
      const ctx = getContext(parentUserId);
      return ctx.user.parentUserId!;
    });

  const getAdvisor = async (): Promise<Advisor> => {
    const response = await api.get<Advisor>('/advisor');
    return response.data;
  };

  const getUser = async (): Promise<Assistant> => {
    const response = await api.get<Assistant>('/user');
    return response.data;
  };

  const searchAdvisorList = async (searchParams: Partial<SearchUser>) => {
    try {
      const response = await api.get<SearchAdvisor[]>(`/advisor/search`, {
        params: searchParams,
      });
      return response.data;
    } catch (err) {
      appInsights.trackException({ exception: err as Error, severityLevel: 3 });
      errorToast(i18next.t('toastNotifications.error'));
    }
    return null;
  };

  return {
    getAdvisor,
    getUser,
    searchAdvisorList,
  };
};
