import { orchestrator } from '../orchestrator';

import { getMicroApplicationList } from './navigation';

export const settings = {
  hideNavigation: true,
  allowRules: ['camera'],
  customSandboxRules: ['allow-top-navigation', 'allow-downloads'],
  iframeCreationInterceptor: (
    iframe: HTMLIFrameElement,
    _viewGroup: string,
    { pathSegment }: { pathSegment: string },
  ) => {
    const customFrame = iframe;

    const loginAppId = getMicroApplicationList().login.id;

    const ALLOW_ANONYMOUS_ACCESS_FOR = [loginAppId];

    const authData = orchestrator().auth().store.getAuthData();
    const hasAccessToken = !!authData?.accessToken;

    if (window.location.href.slice(-1) === '/') {
      window.location.href = window.location.href.slice(0, -1);
    }

    if (!hasAccessToken && !ALLOW_ANONYMOUS_ACCESS_FOR.includes(pathSegment)) {
      orchestrator().navigation().navigate(`/${loginAppId}`);
    } else {
      customFrame.name = 'main';

      if (window.location.search.length) {
        customFrame.src = `${iframe.src}${window.location.search}`;
      }
    }

    return customFrame;
  },
};
