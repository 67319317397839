import { getEnvironmentConfig } from '@dvag/dfs-constant-config';

declare global {
  interface Window {
    userlike: {
      userlikeCloseButton: () => void;
    };
  }
}

export const getEnvConfig = () => getEnvironmentConfig();
